import React, { useState } from 'react'
import styled from 'styled-components';

import Section from './layout/Section'
import Title from './layout/Title'

import ArrowIcon from './icons/Arrow'

const ContactForm = ({ content }) => {
	const [services, setServices] = useState({})

	return (
		<Section content={content} isReadWidth background="pattern">
			<Title subTitle={content.subTitle}>{content.title}</Title>

			<form name="contact" method="POST" action={`https://formsubmit.co/${content.mailAddress}`}>
				<input type="hidden" name="_subject" value="Neue Kontaktanfrage!" />
				<input type="text" name="_honey" style={{ display: 'none' }} />
				<input type="hidden" name="_template" value="table" />

				<Label>Dein Name: <Input type="text" name="name" required /></Label>
				<Label>Deine Mailadresse: <Input type="email" name="email" required /></Label>

				<input type="hidden" name="services" value={Object.keys(services).map(key => services[key] ? key : null).filter(s => s !== null).join(', ')} />

				{content.services.length !== 0 && (
					<FieldSet>
						<Legend>Angebote:</Legend>
						{content.services.split('\n').map((service, i) => service !== '' && (
							<CheckboxWrapper key={i}>
								<Checkbox id={`contact-checkbox-${i}`} type="checkbox" value={service}
									onChange={(e) => setServices({
										...services,
										[service]: e.target.checked
									})} />
								<CheckboxLabel htmlFor={`contact-checkbox-${i}`}>{service}</CheckboxLabel>
							</CheckboxWrapper>
						))}
					</FieldSet>
				)}

				<Label>Kurznachricht: <Input as="textarea" rows="4" name="message" required /></Label>

				<Button type="submit" className="button is-type-full">
					<ArrowIcon isWhite className="button__arrow" />
					Kontakt aufnehmen
					</Button>
			</form>
		</Section >
	)
}

export default ContactForm

const Input = styled.input`
  -webkit-appearance: none;
  border: 1px solid #b7b7b7;
  border-radius: 5px;
  box-sizing: border-box;
  color: ${({ theme }) => theme.color.textGray};
  display: block;
  font-family: ${({ theme }) => theme.font.base};
  padding: .5rem .8rem;
  font-size: 1rem;
  margin: .2rem 0 0 0;
  outline: none;
  width: 100%;
  transition: border-color .2s;

  &:focus {
	border-color: ${({ theme }) => theme.color.primary};
  }
`;

const FieldSet = styled.fieldset`
  border: none;
  padding: 0;
  margin: 0 0 1rem 0;
`;

const Legend = styled.legend`
  display: block;
  font-size: .9rem;
  margin: 0 0 .3rem 0;
`;

const CheckboxWrapper = styled.div`
  display: block;
  margin: 0 0 .5rem 0;
`;

const Checkbox = styled.input`
  
`;

const CheckboxLabel = styled.label`
  padding: 0 0 0 .5rem;
  font-size: 1rem;
`;

const Label = styled.label`
  display: block;
  font-size: .9rem;
  margin: 0 0 1rem 0;
`;

const Button = styled.button`
  margin: 1rem 0 0 0;
`;