import React from 'react'

import Section from './layout/Section'
import Title from './layout/Title'
import RichText from './layout/RichText'
import Grid from './layout/Grid'
import Button from './Button'

const Text = ({ content }) => {
	const isWhite = content.background === 'cyan'

	return (
		<Section content={content}>
			{content.title && (
				<Title subTitle={content.subtitle} isWhite={isWhite}>
					{content.title}
				</Title>
			)}

			{content.textCol && content.textCol.length !== 0 ? (
				<Grid inRow={2} components={[
					<RichText text={content.text} isWhite={isWhite} isMultiCol />,
					<RichText text={content.textCol} isWhite={isWhite} isMultiCol />
				]} />
			) : (
					<RichText text={content.text} isWhite={isWhite} />
				)}

			{(content.button && content.button.length !== 0 && content.button[0].component === 'button') && (
				<Button content={content.button[0]} isWhite={isWhite} />
			)}
		</Section>
	)
}

export default Text