import React from 'react'
import styled from 'styled-components'

import Section from './layout/Section'
import Img from './layout/Image'
import Divider from './layout/Divider'

const Quote = ({ content }) => (
	<Section content={content} isShaded>
		<Image image={content.image} sizes={{
			small: { y: 700 },
			medium: { y: 700 },
			large: { y: 800 }
		}} />

		<Wrapper className="read-width">
			<Text className={content.text.length > 200 && 'is-long'}>{content.text}</Text>

			<Divider isWhite />

			<Author>{content.author}</Author>
		</Wrapper>
	</Section>
)

export default Quote

const Wrapper = styled.blockquote`
color: ${({ theme }) => theme.color.textWhite};
position: relative;
text-align: center;
padding: 5rem 0px 4rem 0px;
`

const Image = styled(Img)`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
`;

const Text = styled.p`
margin: 0 0 20px 0;

&.is-long {
	font-size: 1rem;
	font-weight: normal;
}
`

const Author = styled.cite`
font-family: ${({ theme }) => theme.font.heading};
font-size: 1.8rem;
`