import React from 'react'
import Components from './_components.js';

const Page = (props) => (
	<main>
		{props.blok.body && props.blok.body.map((blok, index) =>
			React.createElement(Components(blok.component), {
				key: blok._uid,
				content: blok,
				settings: props.settings,
				firstElement: index === 0
			}))}
	</main>
)

export default Page