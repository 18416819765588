import React from 'react'

import Page from './_page'
import Text from './Text'
import Quote from './Quote'
import Intro from './Intro'
import BlogTeaser from './BlogTeaser'
import ServiceTeaser from './ServiceTeaser'
import ContactForm from './ContactForm'

const ComponentList = {
	'page': Page,
	'intro': Intro,
	'text': Text,
	'quote': Quote,
	'blogTeaser': BlogTeaser,
	'serviceTeaser': ServiceTeaser,
	'contactForm': ContactForm
}

const Component404 = ({ content }) => <div>Component {content.component} is not defined.</div>

const Components = (type) => {
	if (typeof ComponentList[type] === 'undefined') {
		return Component404
	}
	return ComponentList[type]
}

export default Components