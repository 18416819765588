import React from 'react'
import styled from 'styled-components';

import Divider from './Divider'

const Title = ({ children, subTitle, isWhite, isSpiral }) => (
	<Wrapper>
		<Heading>{children}</Heading>

		<Divider isWhite={isWhite} isSpiral={isSpiral} />

		{subTitle && <Sub>{subTitle}</Sub>}
	</Wrapper>
)

export default Title;

const Wrapper = styled.div`
  margin: 0 0 2rem 0;
`;
const Heading = styled.h2`
  text-align: center;
  margin: 0 0 10px 0;
  hyphens: auto;
`;
const Sub = styled.strong`
  display: block;
  text-align: center;
  font-size: 1.1rem;
`;