import React from 'react'
import styled from 'styled-components';
import { Link as GatsbyLink } from 'gatsby'

import Section from './layout/Section'
import Title from './layout/Title'
import Img from './layout/Image'
import Grid from './layout/Grid'
import Link from './layout/Link'
import ArrowIcon from './icons/Arrow'

const BlogTeaser = ({ content, settings }) => {
	let items = content.showAll ? settings.blogItems : (content.items || [])
	const isWhite = content.background === 'cyan'

	// sort items by date
	if (items && items.length !== 0) {
		items = items.sort((a, b) => new Date(b.published_at) - new Date(a.published_at))
	}

	return (
		<Section content={content} background={content.background}>
			{content.title && <Title isSpiral isWhite={isWhite}>{content.title}</Title>}

			{!items || items.length === 0 || !items[0].content ? (
				<Error>Inhalt wird erst beim Speichern aktualisiert.</Error>
			) : (
				<Grid inRow={3} components={items.map(post => (
					<Item to={`/${post.full_slug}`}>
						<ImageWrapper>
							<Image image={post.content.image} sizes={{
								small: {
									x: 400,
									y: 200
								}
							}} />
						</ImageWrapper>

						<Info>
							<ItemTitle>{post.name}</ItemTitle>

							<Categories>
								{post.content.categories.map(category => <Category key={category}>{category}</Category>)}
							</Categories>

							<Arrow isWhite />
						</Info>
					</Item>
				))} />
			)}


			{!content.showAll && (
				<ShowAll>
					<Link to="/blog" isWhite={isWhite}>Alle Beiträge</Link>
				</ShowAll>
			)}
		</Section>
	)
}

export default BlogTeaser

const infoPadding = '1.5rem'

const Item = styled(GatsbyLink)`
  background: ${({ theme }) => theme.color.secondary};
  border-radius: 10px;
  color: ${({ theme }) => theme.color.textWhite};
  display: block;
  text-decoration: none;
  overflow: hidden;
  position: relative;
  height: 340px;
`;

const Image = styled(Img)`
  height: 100%;
  width: 100%;
  transition: transform .3s ease-out;

  ${Item}:hover & {
	  transform: scale(1.05);
  }
`

const ImageWrapper = styled.div`
  height: 200px;
  width: 100%;
  overflow: hidden;
`;

const Info = styled.div`
  background: ${({ theme }) => theme.color.secondary};
  bottom: 0;
  padding: ${infoPadding};
  position: absolute;
  left: 0;
  width: 100%;
`;

const ItemTitle = styled.h3`
  font-size: 2rem;
  margin: 0 0 .7rem 0;
`;

const Categories = styled.ul`
  padding: 0 3rem 0 0;
  margin: 0 0 -8px 0;
`;

const Category = styled.li`
  background: ${({ theme }) => theme.color.primary};
  border-radius: 10px;
  display: inline-block;
  font-size: .7rem;
  font-weight: bold;
  line-height: 1;
  margin: 0 10px 8px 0;
  padding: 5px 10px;
  white-space: nowrap;
`;

const Arrow = styled(ArrowIcon)`
  bottom: ${infoPadding};
  position: absolute;
  right: ${infoPadding};
  width: 2.3rem;

  transition: transform .2s;
  transform-origin: right center;

  ${Item}:hover & {
	transform: scale(1.1, 1);
  }
`;

const ShowAll = styled.div`
  display: flex;
  justify-content: center;
  margin: 1rem 0 0 0;
`;

const Error = styled.strong`
  color: red;
  display: block;
  text-align: center;
  width: 100%;
`;