import React from 'react'
import Components from '../components/_components.js'
import Layout from '../layout'

class StoryblokEntry extends React.Component {
	static getDerivedStateFromProps(props, state) {
		if (state.story.uuid === props.pageContext.story.uuid) {
			return null
		}

		return StoryblokEntry.prepareStory(props)
	}

	static prepareStory(props) {
		const story = Object.assign({}, props.pageContext.story)
		story.content = JSON.parse(story.content)

		const settings = props.pageContext.settings

		// allow site-specific meta tags
		if (story.content?.seo?.title && story.content.seo.title !== '') settings.seo.title = story.content.seo.title
		if (story.content?.seo?.description && story.content.seo.description !== '') settings.seo.description = story.content.seo.description

		return { story, settings }
	}

	constructor(props) {
		super(props)

		this.state = StoryblokEntry.prepareStory(props)
	}

	render() {
		let content = this.state.story.content
		let settings = this.state.settings

		return (
			<Layout settings={settings}>
				{React.createElement(Components(content.component), { key: content._uid, blok: content, settings })}
			</Layout>
		)
	}
}

export default StoryblokEntry
