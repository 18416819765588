import React from 'react'
import SbEditable from 'storyblok-react'

import Hero from './layout/Hero'

const Intro = ({ content }) => (
	<SbEditable content={content}>
		<Hero
			title={content.title}
			image={content.image}
			youtubeId={content.youtubeId}
			isLarge={content.isLarge} />
	</SbEditable>
)

export default Intro