import React from 'react'
import styled from 'styled-components';

const Grid = ({ components, inRow, fluid }) => (
	<Wrapper className={!fluid && 'max-width'}>
		<Row>
			{components.map((component, i) => (
				<Col key={i} inRow={inRow}>
					{component}
				</Col>
			))}
		</Row>
	</Wrapper>
)

export default Grid

const Wrapper = styled.div`
  overflow: hidden;
`;

const Row = styled.div`
  display: flex;
  flex: 0 1 auto;
  flex-wrap: wrap;
  margin: 0 -.7rem;
`;

const Col = styled.div`
  display: block;
  padding: 0 .7rem;
  flex: 1;
  min-width: 100%;

  &:not(:last-child) {
	  margin: 0 0 1.4rem 0;
  }

  @media only screen and (min-width: ${({ theme }) => theme.breakpoint.medium}) {
	  flex-basis: ${props => 100 / props.inRow}%;
  	  max-width: ${props => 100 / props.inRow}%;
	  min-width: auto;
  }
`;