import React from 'react'
import styled from 'styled-components'

import Section from './layout/Section'
import Title from './layout/Title'
import Img from './layout/Image'
import Grid from './layout/Grid'
import Link from './layout/Link'

const ServiceTeaser = ({ content }) => {
	const isWhite = content.background === 'cyan'

	return (
		<Section content={content} background={content.background} isMaxWidth>
			<Title isSpiral isWhite={isWhite}>{content.title}</Title>

			<Grid components={content.items.map((service) => (
				<Item key={service._uid}>
					<ItemImageWrapper>
						<ItemImage image={service.image} sizes={{
							small: {
								x: 150,
								y: 150
							}
						}} />

						<SVGCircle isWhite={isWhite} version="1.0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 1264">
							<path  d="M593 2c-25 2-38 3-38 5l-15 3-24 3-60 15-26 8a597 597 0 00-227 132A542 542 0 0036 404c-11 27-24 72-29 103a296 296 0 00-4 97c2-1 4-7 8-28l5-24v41c0 25 0 28 4 21 2-2 3-8 5-18l2-15-1 19 1 28c1 9 1 9 3 6l3-29h2c2 0 6-15 12-49a1155 1155 0 0118-75l-1 8-1 9 1-2c0-1 1-1 2 1 0 2 2 3 2 3l5-8c2-4 3-6 3-4l1 3v12c-1 15 0 27 3 27 2 0 4-7 11-27 7-19 7-20 8-17a1130 1130 0 0141-76 735 735 0 01117-147c-15 18-22 31-18 31 2 0 13-8 33-25 59-50 99-78 150-102a523 523 0 01369-33 531 531 0 01385 551 550 550 0 01-808 436 556 556 0 00319 68 558 558 0 00383-197l-3 6a560 560 0 01-166 138 561 561 0 01-272 60c-20 2-78-4-116-13a591 591 0 01-312-189l16 19a568 568 0 00320 180l20 3a476 476 0 01-56-10 561 561 0 01-322-214l-11-14 18 25a572 572 0 00506 221l9-1-9 1a477 477 0 01-231-21A584 584 0 0196 830a589 589 0 0080 158c-23-30-39-56-61-100a569 569 0 00-18-31l5 17 3 8a573 573 0 00185 231 776 776 0 00-28-20 587 587 0 01-118-129l9 15c-2 2 13 21 37 51 2 3 0 2-4-2-7-6-9-5-4 2l3 6-5-5A661 661 0 0158 817l-1-3v3l7 21a616 616 0 00255 319 588 588 0 00592 37 668 668 0 00169-117c2 0-3 7-10 13a622 622 0 01-698 105A623 623 0 0123 708l-6-38c-3-2-2 31 3 58a622 622 0 00366 481 631 631 0 00866-406 664 664 0 0022-103 636 636 0 01-107 299 627 627 0 0064-605l-8-21A558 558 0 00895 53l-29-12A587 587 0 00593 2zM414 52zm-82 57l-10 7c-39 24-86 61-115 92a522 522 0 01-21 18 627 627 0 01146-117zM115 315l-4 7a426 426 0 00-29 56 564 564 0 00-36 82 543 543 0 0169-145zm-44 95a748 748 0 00-38 143l-3 14-1 3a474 474 0 0143-161zM18 545h-1l1-2v2zm13 36c-1 2-1 4-2 3l1-7 1-3v7zm218 503a591 591 0 00321 143l31 6a589 589 0 01-380-173l-12-12 14 13 26 23zm317 114h-3c-1-1 0-1 2-1 1 0 2 0 1 1z" />
							<path d="M1149 1023a631 631 0 01-432 241 635 635 0 00432-241z" />
						</SVGCircle>
					</ItemImageWrapper>

					<ItemTitle>{service.title}</ItemTitle>

					<ItemDescription>{service.description}</ItemDescription>

					<Link link={service.link} isWhite={isWhite}>Mehr erfahren</Link>
				</Item>
			))} />
		</Section>
	)
}

export default ServiceTeaser

const Item = styled.div`
  text-align: center;
  margin: 1rem 0;
`

const ItemImageWrapper = styled.div`
  height: 150px;
  position: relative;
  margin: 0 auto 1.2rem auto;
  width: 150px;
`;

const SVGCircle = styled.svg`
  position: absolute;
  top: -3px;
  left: -3px;
  height: calc(100% + 6px);
  width: calc(100% + 6px);

  path {
	  fill: ${({ theme, isWhite }) => isWhite ? theme.color.textWhite : theme.color.primary};
  }
`;

const ItemImage = styled(Img)`
  border-radius: 50%;
  overflow: hidden;
  height: 100%;
  width: 100%;
`;

const ItemTitle = styled.h3`
  margin: 0 0 .6rem 0;
`;

const ItemDescription = styled.p`
  max-width: 300px;
  margin: 0 auto .8rem auto;
`;